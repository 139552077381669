<template>
    <div class="promo-player-landing">
        <BaseAudioPlayer
                :descr="playerDescr"
        ></BaseAudioPlayer>
    </div>
</template>

<script>
import BaseAudioPlayer from '@/components/meditation/BaseAudioPlayer.vue';

export default {
  name: 'PromoPlayerLanding',
  components: {
    BaseAudioPlayer,
  },
  data() {
    return {
      playerDescr: 'true',
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    newMeditationsList() {
      return this.$store.state.meditations.newMeditationsList;
    },
  },
  mounted() {
    this.showPreview(1);
  },
  beforeDestroy() {
    this.$store.commit('hidePlayer');
  },
  methods: {
    showPreview(id) {
      let groupID = '';
      let medID = '';

      this.meditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (itemList.id === id) {
            groupID = item.groupName;
            medID = itemList.url;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      this.newMeditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (itemList.id === id) {
            groupID = item.groupName;
            medID = itemList.url;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer');
    },
    getMainColor(groupID) {
      let mainColor = '';

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      this.newMeditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.promo-player-landing {
}
</style>
