<template>
  <div class="landing-page">
    <PromoPlayerLanding></PromoPlayerLanding>
  </div>
</template>

<script>
// @ is an alias to /src
import PromoPlayerLanding from '@/components/secondary-pages/PromoPlayerLanding.vue';

export default {
  name: 'Home',
  components: {
    PromoPlayerLanding,
  },
};
</script>
